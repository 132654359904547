import React, { useMemo, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../components/items/page-title";
import Field from "../components/form/formik/Field";
import { getCreateFields, validationSchema, emptyInitialValues } from "../utils/walletsFields";
import Loading from "../components/Loading";
import { WalletCreate, typeOptions } from "../models/Wallet";
import { useGetItemQuery, useUpdateItemMutation, useDeleteItemMutation } from "../api/wallets";
import { useGetCurrenciesQuery } from "../api/currencies";
import { OptionsQuery } from "../types/global";

const ROUTE = "/wallets";

const UpdateWallet = () => {
  const [initialValues, setInitialValues] = useState<WalletCreate>(emptyInitialValues);
  const params = useParams() as { id: string };
  const { data, isLoading, isSuccess } = useGetItemQuery(params.id);
  const item = useMemo(() => (isSuccess ? data.model : emptyInitialValues), [isSuccess, data]);

  const navigate = useNavigate();
  const fields = useMemo(
    () =>
      getCreateFields({
        currenciesQuery: useGetCurrenciesQuery as OptionsQuery,
      }),
    [typeOptions, useGetCurrenciesQuery],
  );

  useEffect(() => {
    setInitialValues({
      address: item.address,
      currency: item.currency,
      domain: item.domain,
      group: item.group,
      status: Boolean(item.status),
      onCheck: Boolean(item.onCheck),
      type: typeOptions.find((option) => option.value === item.type) || null,
    });
  }, [item, typeOptions]);

  const [updateItem, { isLoading: isLoadingUpdate }] = useUpdateItemMutation();
  const [deleteItem, { isLoading: isLoadingDelete }] = useDeleteItemMutation();

  const handleSubmit = async (values: WalletCreate) => {
    await updateItem({ data: values, id: params.id });
    navigate(ROUTE);
  };

  const handleDelete = async () => {
    await deleteItem(params.id);
    navigate(ROUTE);
  };

  if (isLoading || !item) return <Loading />;

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={ROUTE}>
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
          <Button
            className="w-auto mb-3"
            variant="danger"
            disabled={isLoadingDelete}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Link>
        <PageTitle title="Updating wallet" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              {initialValues && (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  <Form className="w-100 px-4">
                    <div className="row">
                      {fields.map((field) => (
                        <Field key={field.name} {...field} />
                      ))}
                    </div>
                    <Button
                      type="submit"
                      className="form-control w-25 m-auto d-block"
                      variant="primary"
                      disabled={isLoadingUpdate}
                    >
                      Update
                    </Button>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UpdateWallet;
