import React, { useMemo, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../components/items/page-title";
import Field from "../components/form/formik/Field";
import { createFields, validationSchema, initialValues as emptyInitialValues } from "../utils/pairFields";
import { CreatePairType, typeOptions } from "../models/Pair";
import { useUpdatePairMutation, useGetPairQuery, useDeletePairMutation } from "../api/pairs";
import { useGetCurrenciesQuery } from "../api/currencies";
import { useGetClientsQuery } from "../api/clients";
import Loading from "../components/Loading";
import { OptionsQuery } from "../types/global";

const UpdatePair = () => {
  const [initialValues, setInitialValues] = useState<CreatePairType | null>(null);
  const params = useParams() as { id: string };
  const { data, isLoading } = useGetPairQuery(params.id);
  const pair = data?.model || null;

  const navigate = useNavigate();
  const fields = useMemo(
    () =>
      createFields({
        currenciesQuery: useGetCurrenciesQuery as OptionsQuery,
        clientsQuery: useGetClientsQuery,
      }),
    [useGetClientsQuery, useGetCurrenciesQuery],
  );

  useEffect(() => {
    const type = typeOptions.find(option => option.value === pair?.type) || emptyInitialValues.type;

    setInitialValues({
      type,
      domain: pair?.domain || emptyInitialValues.domain,
      percent: pair?.percent || emptyInitialValues.percent,
      isSelectedPair: !!pair?.isSelectedPair || emptyInitialValues.isSelectedPair,
      available: pair?.available || emptyInitialValues.available,
      client: pair?.client || emptyInitialValues.client,
      currencyFrom: pair?.currencyFrom || emptyInitialValues.currencyFrom,
      currencyTo: pair?.currencyTo || emptyInitialValues.currencyTo,
      min: pair?.min || emptyInitialValues.min,
      max: pair?.max || emptyInitialValues.max,
    });
  }, [pair]);

  const [updatePair, { isLoading: isLoadingUpdate }] = useUpdatePairMutation();
  const [deletePair, { isLoading: isLoadingDelete }] = useDeletePairMutation();

  const handleSubmit = async (values: CreatePairType) => {
    await updatePair({ data: values, id: params.id });
    navigate("/pairs");
  };

  const handleDelete = async () => {
    await deletePair(params.id);
    navigate("/pairs");
  };

  if (isLoading || !pair) return <Loading />;

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/pairs">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
          <Button
            className="w-auto mb-3"
            variant="danger"
            disabled={isLoadingDelete}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Link>
        <PageTitle title="Updating pair" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              {initialValues && (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  <Form className="w-100 px-4">
                    <div className="row">
                      {fields.map((field) => (
                        <Field key={field.name} {...field} />
                      ))}
                    </div>
                    <Button
                      type="submit"
                      className="form-control w-25 m-auto d-block"
                      variant="primary"
                      disabled={isLoadingUpdate}
                    >
                      Update
                    </Button>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UpdatePair;
