import React from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import { UnknownRecord } from "../../../types/global";
import { IField } from "../../../types/IField";
import { formatDateToState, stringToDate } from "../../../utils/formats";

const DateRangeField = <T extends UnknownRecord>({ name, placeholder, label }: IField<T>) => {
  const [, meta, helpers] = useField(name);
  const { value }: { value: [string, string] } = meta;
  const { setValue } = helpers;

  const handleChange = (date: [Date | null, Date | null]) => {
    setValue([formatDateToState(date[0]), formatDateToState(date[1])]);
  };

  const startDate = stringToDate(value[0]);
  const endDate = stringToDate(value[1]);

  return (
    <>
      {label && <label className="form-label-test">{label}</label>}
      <DatePicker
        className="form-control"
        onChange={handleChange}
        placeholderText={placeholder}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        isClearable
        dateFormat="yyyy/MM/dd"
      />
    </>
  );
};

export default DateRangeField;
