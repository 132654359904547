import React from "react";
import { useField } from "formik";
import { FormControl } from "react-bootstrap";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import { UnknownRecord } from "../../../types/global";
import { IFieldDatepicker } from "../../../types/IField";
import { stringTimeToDate, formatTimeWithSeconds } from "../../../utils/formats";

const TimeField = <T extends UnknownRecord>({ name, label, showSec }: IFieldDatepicker<T>) => {
  const [, meta, helpers] = useField(name);
  const { value, touched, error } = meta;

  const selectedValue = stringTimeToDate(value);
  const { setValue } = helpers;

  const handleChange = (date: Date) => {
    setValue(formatTimeWithSeconds(date));
  };

  return (
    <>
      {label && <label className="form-label-test">{label}</label>}
      <DatePicker
        className="form-control"
        onChange={handleChange}
        selected={selectedValue}
        showTimeSelect
        showTimeSelectOnly
        timeCaption="Time"
        dateFormat={showSec ? "HH:mm:ss" : "HH:mm"}
        timeFormat={showSec ? "HH:mm:ss" : "HH:mm"}
        injectTimes={[setHours(setMinutes(setSeconds(new Date(), 59), 59), 23)]}
      />
      {touched && error ? (
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      ) : null}
    </>
  );
};

export default TimeField;
