import React, { useState } from "react";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useCreateTransactionMutation } from "../api/transactions";
import {
  ManualTransactionCreationType,
  manualTransactionCreationValues,
} from "../models/Transaction";
import { TypesOfInput } from "../types/IField";
import Field from "./form/formik/Field";
import { useGetClientsQuery } from "../api/clients";
import { OptionsQuery } from "../types/global";

const ManualTransactionCreation = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [createTransaction, { isLoading }] = useCreateTransactionMutation();

  const handleSubmit = (values: ManualTransactionCreationType) => {
    createTransaction(values)
      .unwrap()
      .then(() => setIsShowModal(false));
  };

  return (
    <>
      <Button variant="primary" className="mb-3" onClick={() => setIsShowModal(true)}>
        Create transaction
      </Button>
      <Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={manualTransactionCreationValues}
            validationSchema={Yup.object().shape({
              client: Yup.object().typeError("Required"),
              amount: Yup.number(),
            })}
            onSubmit={handleSubmit}
          >
            <Form>
              <Field
                type={TypesOfInput.SELECT}
                name="client"
                size={12}
                valueName="id"
                query={useGetClientsQuery as OptionsQuery}
                formatOptionLabel={(option) => (
                  <div>
                    {option.telegramId}
                    {option.username && <span> / {option.username}</span>}
                  </div>
                )}
              />
              <Field type={TypesOfInput.NUMBER} name="amount" size={12} />
              <Button disabled={isLoading} type="submit" className="w-100" variant="success">
                Save
              </Button>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManualTransactionCreation;
