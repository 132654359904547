import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import PageTitle from "../components/items/page-title";
import { FakeTransactionForm } from "../models/FakeTransaction";
import Field from "../components/form/formik/Field";
import { handleCopy } from "../api/utils";
import example from "../assets/images/example.jpg";
import { getCreateFields, initialValues } from "../utils/fakeTransactionsFields";
import { useGetRetentionsQuery } from "../api/retentions";
import ColorStain from "../components/ColorStain";
import { OptionsQuery } from "../types/global";

const FakeTransaction = () => {
  const [transaction, setTransaction] = useState<FakeTransactionForm | null>(null);

  const handleSubmit = (values: FakeTransactionForm) => {
    setTransaction(values);
  };

  const getRows = (transaction: FakeTransactionForm) => [
    {
      name: "Адрес",
      value: (
        <p
          className="p-1 bg-accent rounded-lg flex justify-between cursor-pointer max-w-[80%] m-0"
          onClick={() => handleCopy(transaction.address)}
        >
          <span className="pr-2 md:pr-4 truncate max-w-[160px] md:max-w-[200px]">
            {transaction.address}
          </span>
          <span className="material-icons ">content_copy</span>
        </p>
      ),
    },
    {
      name: "Пара",
      value: transaction.currency,
    },
    {
      name: "Сумма",
      value: `${transaction.amount}`,
    },
    {
      name: "Статус",
      value: transaction.status?.value,
    },
    {
      name: "Создано",
      value: transaction.createdAt,
      // value: new Date(transaction.createdAt).toLocaleString(),
    },
    {
      hide:
        !transaction.retentionEnum || !transaction.retentionComment || !transaction.retentionText,
      name: "Информация",
      value: (
        <span className="text-red-600">
          {transaction.retentionText}
          <br />
          {transaction.retentionComment && (
            <span className="text-yellow-600">{transaction.retentionComment}</span>
          )}
        </span>
      ),
    },
  ];

  const getTransactionsViews = (transaction: FakeTransactionForm) => [
    <div className="TransactionDesign-1 rounded-lg">
      <ColorStain size={100} color="blue" className="u-top-0" />
      <div className="blur-bg">
        <div className="p-4">
          <p className="text-center">Заказ №-{transaction.code}</p>
          <table className="border-separate border-spacing-y-2">
            <tbody>
              {getRows(transaction).map((row) => (
                <tr key={row.name} className="mt-2">
                  <td className="pr-2">{row.name}:</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>,
    <div className="blur-bg rounded-lg TransactionDesign-2">
      <div className="p-4">
        <p className="text-center">Заказ №-{transaction.code}</p>
        <table className="border-separate border-spacing-y-2">
          <tbody>
            {getRows(transaction).map((row) => (
              <tr key={row.name} className="mt-2">
                <td className="pr-2">{row.name}:</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>,
    <div className="blur-bg rounded-lg TransactionDesign-3">
      <div className="p-4">
        <p className="text-center">Заказ №-{transaction.code}</p>
        <table className="border-separate border-spacing-y-2">
          <tbody>
            {getRows(transaction).map((row) => (
              <tr key={row.name} className="mt-2">
                <td className="pr-2">{row.name}:</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>,
    <div className="blur-bg rounded-lg TransactionDesign-4">
      <div className="p-4">
        <p className="text-center">Заказ №-{transaction.code}</p>
        <table className="border-separate border-spacing-y-2">
          <tbody>
            {getRows(transaction).map((row) => (
              <tr key={row.name} className="mt-2">
                <td className="pr-2">{row.name}:</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>,
  ];

  const fields = useMemo(
    () =>
      getCreateFields({
        retentionsQuery: useGetRetentionsQuery as OptionsQuery,
      }),
    [],
  );

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Fake transaction" />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {({ values, setFieldValue }) => {
                  useEffect(() => {
                    if (!values.retentionEnum) return;
                    setFieldValue("retentionText", values.retentionEnum?.text);
                    setFieldValue("retentionComment", values.retentionEnum?.comment);
                  }, [values.retentionEnum]);
                  return (
                    <Form className="w-100">
                      <div className="row">
                        {fields.map((field) => (
                          <Field key={field.name} {...field} />
                        ))}
                      </div>
                      <Button
                        type="submit"
                        className="form-control w-25 m-auto d-block mb-5"
                        variant="primary"
                      >
                        Create
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
              <img src={example} alt="example" className="mw-400px m-auto d-block" />
            </div>
          </div>
          {!!transaction && (
            <div className="card mt-5">
              <div className="card-body">
                <Row>
                  {getTransactionsViews(transaction).map((view, index) => (
                    <Col key={index} xs={1} sm={6} lg={4} className="TransactionDesign mt-3">
                      {view}
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default FakeTransaction;
