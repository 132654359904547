import React, { useMemo, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

import PageTitle from "../components/items/page-title";
import Table from "../components/tables/Table";
import { PaginationType } from "../components/tables/types";
import { ArrayOfDate, OptionsQuery, PAGINATION } from "../types/global";
import Loading from "../components/Loading";
import { useLazyGetTransactionsQuery, useGetTransactionStatusesQuery } from "../api/transactions";
import getColumnDefsTransactions from "../utils/transactionsColumn";
import { formatDateToState, getStatusColor, stringToDate } from "../utils/formats";
import {
  TransactionFilterForm,
  TransactionFilterCookies,
  TransactionStatus,
  TransactionRequest,
} from "../models/Transaction";
import { getCookies, setCookies } from "../api/utils";
import FilterForm from "../components/form/formik/FilterForm";
import { getFilterFields, initialFilterValues } from "../utils/transactionsFields";
import { useGetClientsQuery } from "../api/clients";
import Icon from "../components/items/Icon";
import ManualTransactionCreation from "../components/ManualTransactionCreation";

const TRANSACTION_FILTERS = "transactionFilters";

const Transactions = () => {
  const transactionFilter: TransactionFilterCookies = useMemo(
    () => getCookies(TRANSACTION_FILTERS),
    [],
  );
  const [pagination, setPagination] = useState<PaginationType>(PAGINATION);
  const [filterValues, setFilterValues] = useState<TransactionFilterForm>(initialFilterValues);
  const [selectedStatuses, setSelectedStatuses] = useState<TransactionStatus[]>(
    transactionFilter?.statuses ? transactionFilter.statuses : [],
  );
  const [selectedClient, setSelectedClient] = useState<string | null>(
    transactionFilter?.clientId || null,
  );
  const [selectedDate, setSelectedDate] = useState<ArrayOfDate>(
    transactionFilter?.createdAt
      ? transactionFilter?.createdAt
      : ["22/01/1", formatDateToState(Date())],
  );

  const [fetch, query, { lastArg }] = useLazyGetTransactionsQuery();
  const statusesQuery = useGetTransactionStatusesQuery({
    filter: {
      createdAt: selectedDate,
      clientId: selectedClient,
    },
  });

  const filterFields = useMemo(
    () =>
      getFilterFields({
        statusesQuery: useGetTransactionStatusesQuery as OptionsQuery,
        clientsQuery: useGetClientsQuery,
      }),
    [useLazyGetTransactionsQuery, useGetTransactionStatusesQuery],
  );

  const { data, isLoading } = query;

  const client = useMemo(
    () => data?.list.find((item) => item.client.id === selectedClient)?.client,
    [data?.list, selectedClient],
  );

  const statuses = useMemo(
    () =>
      statusesQuery.data
        ? (Object.entries(statusesQuery.data.list) as unknown as [TransactionStatus, number][])
        : [],
    [statusesQuery.data],
  );

  useEffect(() => {
    const filteredStatuses = statuses.filter(([key]) => selectedStatuses.includes(key)).map(item => item[0]);
    setFilterValues({
      ...initialFilterValues,
      statuses: filteredStatuses,
    });
  }, [selectedStatuses, statuses]);

  useEffect(() => {
    if (!statusesQuery.data || transactionFilter) return;
    setSelectedStatuses(
      Object.keys(statusesQuery.data.list).filter(
        (item) => item !== "visited",
      ) as TransactionStatus[],
    );
  }, [statusesQuery, transactionFilter]);

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }
    const filter: TransactionRequest = {
      createdAt: selectedDate,
    };
    if (selectedStatuses.length) {
      filter.status = selectedStatuses.join(",") || "";
    }
    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  }, [pagination]);

  useEffect(() => {
    const filter: TransactionRequest = {
      createdAt: selectedDate,
    };
    if (selectedStatuses.length) {
      filter.status = selectedStatuses.join(",") || "";
    }
    if (selectedClient) {
      filter.clientId = selectedClient;
    }

    setCookies("transactionFilters", {
      ...filter,
      statuses: selectedStatuses,
    });

    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  }, [selectedDate, selectedStatuses, selectedClient]);

  const handleSelectClient = (clientId: string) => {
    setSelectedClient(clientId);
  };

  const columns = useMemo(
    () =>
      getColumnDefsTransactions({
        handleSelectClient,
        pagination,
      }),
    [pagination],
  );

  if (isLoading) {
    return <Loading />;
  }

  const handleChange = (date: [Date | null, Date | null]) => {
    setSelectedDate([formatDateToState(date[0]), formatDateToState(date[1])]);
  };

  const startDate = stringToDate(selectedDate[0]);
  const endDate = stringToDate(selectedDate[1]);

  const handleFilter = (values: TransactionFilterForm) => {
    const filter: TransactionRequest = {
      ...values,
      createdAt: selectedDate,
      status: values?.statuses?.join(",") || "",
    };
    setSelectedStatuses(values?.statuses as TransactionStatus[]);
    delete filter.statuses;

    if (selectedClient) {
      filter.clientId = selectedClient;
    }

    return fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  };

  const handleChangeStatus = (status: TransactionStatus) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses((prevState) => prevState.filter((item) => item !== status));
    } else {
      setSelectedStatuses((prevState) => [...prevState, status]);
    }
  };

  if (!data) return null;

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Transactions" />
      <FilterForm
        className="mb-3"
        fields={filterFields}
        handleSubmit={handleFilter}
        initialValues={filterValues}
      />
      <ManualTransactionCreation />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  {statuses && (
                    <div className="d-flex flex-wrap">
                      {statuses.map(([statusKey, statusCount]) => (
                        <div className="mb-1" key={statusKey}>
                          <Button
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: getStatusColor(statusKey),
                              borderColor: selectedStatuses.includes(statusKey)
                                ? "black"
                                : getStatusColor(statusKey),
                            }}
                            onClick={() => handleChangeStatus(statusKey)}
                          >
                            <span>{`${statusKey} (${statusCount})`}</span>
                            {selectedStatuses.includes(statusKey) && <Icon name="bin-1" />}
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-5">
                      {selectedClient && (
                        <Button variant="danger" onClick={() => setSelectedClient(null)}>
                          {client?.telegramId}
                        </Button>
                      )}
                    </div>
                    <div className="col-7">
                      <DatePicker
                        className="form-control"
                        onChange={handleChange}
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Table
                setPagination={setPagination}
                className="min-w-screen-lg"
                columns={columns}
                containerClass="overflow-auto"
                fixed
                items={data.list}
                pagination={pagination}
                dataKey="transactions"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Transactions;
