import React, { useState, useRef } from "react";
import { FormikProps, Formik, Form } from "formik";
import { Button, Modal } from "react-bootstrap";

import { ISendMessage, botOptions, sendMessageInitialValues, sendMessageValidationSchema } from "../models/Client";
import { TypesOfInput } from "../types/IField";
import { useGetClientsQuery, useSendMessageMutation } from "../api/clients";
import Field from "./form/formik/Field";
import { OptionsQuery } from "../types/global";

const SendMessageModal = () => {
  const [showModal, setShowModal] = useState(false);
  const formikRef: React.Ref<FormikProps<ISendMessage>> | null = useRef(null);
  const [sendMessage, { isLoading }] = useSendMessageMutation();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (values: ISendMessage) => {
    sendMessage(values).then(handleCloseModal);
  };

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Send message
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Send message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={sendMessageInitialValues}
            onSubmit={handleSubmit}
            validationSchema={sendMessageValidationSchema}
            innerRef={formikRef}
          >
            <Form className="w-100">
              <div className="row">
                <Field
                  name="bot"
                  type={TypesOfInput.SELECT}
                  size={12}
                  options={botOptions}
                />
                <Field
                  name="chatId"
                  label="Chat"
                  type={TypesOfInput.SELECT}
                  labelName="telegramId"
                  valueName="telegramId"
                  size={12}
                  query={useGetClientsQuery as OptionsQuery}
                />
                <Field name="text" size={12} />
                <Field name="customChatId" size={12} />
                <Field name="buttonText" size={12} />
                <Field name="buttonUrl" size={12} />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => formikRef.current?.handleSubmit()}
            disabled={isLoading}
          >
            Send message
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendMessageModal;
