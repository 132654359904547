import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { OptionsQuery, YupValidationFields } from "../types/global";
import { CreateInvitationType } from "../models/Invitation";

export const initialValues: CreateInvitationType = {
  source: "",
  token: "",
  fbPixel: null,
  user: null,
  channel: null,
  sub1: "",
  sub2: "",
  sub3: "",
  sub4: "",
  sub5: "",
};

export const getValidationSchema = (isAdmin: boolean) => {
  const commonSchema = {
    source: Yup.string().required(),
    token: Yup.string(),
    fbPixel: Yup.string().nullable(),
    sub1: Yup.string(),
    sub2: Yup.string(),
    sub3: Yup.string(),
    sub4: Yup.string(),
    sub5: Yup.string(),
  };
  if (isAdmin) {
    return Yup.object<YupValidationFields<CreateInvitationType>>({
      channel: Yup.object().typeError("Required"),
      user: Yup.object().typeError("Required"),
      ...commonSchema
    });
  }

  return Yup.object<YupValidationFields<CreateInvitationType>>({
    channel: Yup.object().typeError("Required"),
    ...commonSchema,
  });
};

type Props = {
  usersQuery: OptionsQuery;
  channelsQuery: OptionsQuery;
}

export const getCreateFields = ({ usersQuery, channelsQuery }: Props): IAllFields<CreateInvitationType>[] => [
  {
    label: "Source",
    name: "source",
    type: TypesOfInput.TEXT,
  },
  {
    label: "fb pixel",
    name: "fbPixel",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Token",
    name: "token",
    type: TypesOfInput.TEXT,
  },
  {
    label: "User",
    query: usersQuery,
    name: "user",
    type: TypesOfInput.SELECT,
    labelName: "affiliate",
    valueName: "id",
  },
  {
    label: "Channel",
    query: channelsQuery,
    name: "channel",
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "channelId",
  },
  {
    label: "sub1",
    name: "sub1",
    type: TypesOfInput.TEXT,
  },
  {
    label: "sub2",
    name: "sub2",
    type: TypesOfInput.TEXT,
  },
  {
    label: "sub3",
    name: "sub3",
    type: TypesOfInput.TEXT,
  },
  {
    label: "sub4",
    name: "sub4",
    type: TypesOfInput.TEXT,
  },
  {
    label: "sub5",
    name: "sub5",
    type: TypesOfInput.TEXT,
  },
];
