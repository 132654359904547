import React, { useRef } from "react";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useGetCurrenciesToGenerateWalletQuery } from "../api/currencies";
import { useGenerateWalletMutation } from "../api/wallets";
import { GenerateWallet } from "../models/Wallet";
import { TypesOfInput } from "../types/IField";
import Field from "./form/formik/Field";
import { OptionsQuery } from "../types/global";

export const GenerateWalletModal = () => {
  const formikRef: React.Ref<FormikProps<GenerateWallet>> | null = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [mutate, { isLoading }] = useGenerateWalletMutation();

  const handleGenerate = async (values: GenerateWallet) => {
    if (!values.currency) return;
    await mutate(values.currency.id);
    setShowModal(false);
  };

  return (
    <>
      <Button className="mb-3" variant="primary" onClick={() => setShowModal(true)}>
        Generate
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>Retention transaction</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              currency: null,
            }}
            onSubmit={handleGenerate}
            validationSchema={Yup.object({
              currency: Yup.object().typeError("Required"),
            })}
            innerRef={formikRef}
          >
            <Form>
              <Field
                type={TypesOfInput.SELECT}
                name="currency"
                labelName="name"
                query={useGetCurrenciesToGenerateWalletQuery as OptionsQuery}
                size={12}
              />
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="success" disabled={isLoading} onClick={() => formikRef.current?.handleSubmit()}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
