import React, { useMemo, useState, useEffect } from "react";

import PageTitle from "../components/items/page-title";
import Table from "../components/tables/Table";
import { PaginationType } from "../components/tables/types";
import { OptionsQuery, PAGINATION } from "../types/global";
import Loading from "../components/Loading";
import { useLazyGetWalletsQuery } from "../api/wallets";
import getColumnDefsWallets from "../utils/walletsColumn";
import { WalletFilter } from "../models/Wallet";
import { emptyFilterValues, getFilterFields } from "../utils/walletsFields";
import FilterForm from "../components/form/formik/FilterForm";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetCurrenciesQuery } from "../api/currencies";
import { GenerateWalletModal } from "../components/GenerateWalletModal";

const Wallets = () => {
  const [pagination, setPagination] = useState<PaginationType>(PAGINATION);
  const [filter, setFilter] = useState<WalletFilter>(emptyFilterValues);

  const [fetch, query, { lastArg }] = useLazyGetWalletsQuery();
  const { data, isLoading } = query;

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }

    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  }, [pagination]);

  useEffect(() => {
    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  }, [filter]);

  const columns = useMemo(() => getColumnDefsWallets({ pagination }), [pagination]);
  const filterFields = useMemo(() => getFilterFields({
    currenciesQuery: useGetCurrenciesQuery as OptionsQuery,
  }), []);

  const handleFilter = (values: WalletFilter) => {
    setFilter(values);
    return false;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Wallets" />
      <Link to="/wallets/new">
        <Button className="mb-3" variant="primary">Create wallet</Button>
      </Link>
      <FilterForm
        className="mb-3"
        fields={filterFields}
        handleSubmit={handleFilter}
        initialValues={emptyFilterValues}
      />
      <GenerateWalletModal />
      <Link to="/wallets/withdrawal">
        <Button className="mb-3" variant="primary">Withdrawal</Button>
      </Link>
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Table
                setPagination={setPagination}
                className="min-w-screen-lg"
                columns={columns}
                containerClass="overflow-auto"
                fixed
                items={data?.list}
                pagination={pagination}
                dataKey="wallets"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Wallets;
