import { UseQuery, UseLazyQuery, LazyQueryTrigger, MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/query";
import * as Yup from "yup";
import { ListRes } from "../api/utils";
import { PaginationQuery } from "../components/tables/types";

export type UnknownRecord = Record<string, unknown>;

export type FormErrors = Partial<{
  [key: string]: string | string[];
}>;

export type ObjectWithKeys<T = string> = {
  [key: string]: T;
};

export type Modify<T, R> = Omit<T, keyof R> & R;

export type FetchedStoreModel<T> = {
  data: T;
  error: null | string;
  loading: boolean;
};

export type FetchOptionsParams = {
  name?: string;
};

export type ArrayOfDate = [string | null, string | null];

export type WeekDays =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type GridSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum LabelColors {
  PRIMARY = "#0d6efd",
  PINK = "#a90de7",

  BLUE = "#005EFF",
  BLUE_LIGHT = "#86E5F0",
  ORANGE = "#FF9F43",
  GREEN = "#26C770",
  VIOLET = "#7467F0",
  RED = "#EA5455",
  GRAY = "#8A8F93",
}

export type YupValidationFields<Model> = Partial<Record<keyof Model, Yup.AnySchema>>;

export type FilterConditionally<Source, Condition> = Pick<
  Source,
  {
    [K in keyof Source]: Source[K] extends Condition ? K : never;
  }[keyof Source]
>;

export type ListItem<T> = T & {
  id: string;
  createdAt: string;
  isSelected?: boolean,
};

export type TypeOptions<T> = {
  label: string;
  value: T;
  color?: LabelColors;
};

export const PAGINATION_TAKE = 100;
export const PAGINATION = {
  take: PAGINATION_TAKE,
  page: 1,
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type FixMeLater = any;
type BaseQueryType = BaseQueryFn<FixMeLater, unknown, unknown, UnknownRecord, UnknownRecord>;
export type Query = UseQuery<QueryDefinition<FixMeLater, BaseQueryType, string, FixMeLater, string>>;
export type LazyQuery = UseLazyQuery<QueryDefinition<unknown, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>, string, FixMeLater, string>>
export type QueryFetch = LazyQueryTrigger<QueryDefinition<void, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>, string, FixMeLater, string>>
export type Mutation = MutationTrigger<MutationDefinition<FixMeLater, BaseQueryType, string, FixMeLater, string>>;

export type OptionsQuery = UseQuery<QueryDefinition<RequestOptions, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, FixMeLater, ListRes<any>, string>>

export type RequestOptions<Filters = any> = {
  pagination: PaginationQuery,
  filter?: Filters;
  searchTerm?: string;
  orderBy: OrderByFormType,
}

export type OrderByFormType = {
  orderBy: TypeOptions<string>,
  sort: OrderByValues,
}

export const defaultSortOrderOption = {
  value: "createdAt",
  label: "createdAt",
};

type OrderByValues = "desc" | "asc";

export const defaultOrderByFilter: OrderByFormType = { orderBy: defaultSortOrderOption, sort: "desc" };
