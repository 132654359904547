import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import { transactionsApi } from "../../api/transactions";
import { pairsApi } from "../../api/pairs";
import { rtkQueryErrorLogger } from "../../api/utils";
import { clientsApi } from "../../api/clients";
import { currenciesApi } from "../../api/currencies";
import { retentionsApi } from "../../api/retentions";
import { walletsApi } from "../../api/wallets";
import { channelsApi } from "../../api/channels";
import { statisticsApi } from "../../api/statistics";
import { smsTemplatesApi } from "../../api/smsTemplates";
import { invitationsApi } from "../../api/invitations";
import { usersApi } from "../../api/users";
import { sitesApi } from "../../api/sites";
import { botsApi } from "../../api/bots";
import { pixelsApi } from "../../api/pixels";
import { botLinksApi } from "../../api/botLinks";
import { statusesApi } from "../../api/statuses";

const reducers = {
  authSlice,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [pairsApi.reducerPath]: pairsApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [retentionsApi.reducerPath]: retentionsApi.reducer,
  [walletsApi.reducerPath]: walletsApi.reducer,
  [channelsApi.reducerPath]: channelsApi.reducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer,
  [smsTemplatesApi.reducerPath]: smsTemplatesApi.reducer,
  [invitationsApi.reducerPath]: invitationsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [sitesApi.reducerPath]: sitesApi.reducer,
  [botsApi.reducerPath]: botsApi.reducer,
  [pixelsApi.reducerPath]: pixelsApi.reducer,
  [botLinksApi.reducerPath]: botLinksApi.reducer,
  [statusesApi.reducerPath]: statusesApi.reducer,
};

const rootReducer = combineReducers(reducers);

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      transactionsApi.middleware,
      statusesApi.middleware,
      pairsApi.middleware,
      clientsApi.middleware,
      currenciesApi.middleware,
      walletsApi.middleware,
      channelsApi.middleware,
      statisticsApi.middleware,
      smsTemplatesApi.middleware,
      invitationsApi.middleware,
      retentionsApi.middleware,
      botsApi.middleware,
      pixelsApi.middleware,
      botLinksApi.middleware,
      usersApi.middleware,
      sitesApi.middleware,
      rtkQueryErrorLogger,
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
