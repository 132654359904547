import React, { useMemo, useState, useEffect } from "react";

import Table from "../components/tables/Table";
import Loading from "../components/Loading";
import { useLazyGetStatisticsClientsQuery } from "../api/statistics";
import { getAmountFromString, getColumnDefsStatisticsClients } from "../utils/statisticsColumn";
import { StatisticClientsFilter } from "../models/Statistic";
import {
  emptyStatisticsClientFilterValues,
  getFilterClientsFields,
} from "../utils/statisticsFields";
import FilterForm from "../components/form/formik/FilterForm";
import { useGetChannelsQuery } from "../api/channels";
import { OptionsQuery } from "../types/global";

const StatisticsClients = () => {
  const [filter, setFilter] = useState<StatisticClientsFilter>(emptyStatisticsClientFilterValues);

  const [fetch, query] = useLazyGetStatisticsClientsQuery();
  const { data, isLoading } = query;

  useEffect(() => {
    fetch({ filter });
  }, [filter]);

  const items = useMemo(() => {
    if (!data) return [];

    const newItems = [...data.list];

    let clientsAmount = 0;
    let messagesAmount = 0;
    let visitedAmount = 0;
    let visitedAmountBrackets = 0;
    let transactionsAmountBrackets = 0;
    let transactionsAmount = 0;
    let retentionsAmountBrackets = 0;
    let retentionsAmount = 0;

    newItems.forEach((item) => {
      const transactionsAmounts = getAmountFromString(item.transactions);
      const retentionsAmounts = getAmountFromString(item.retentions);
      const visitedAmounts = getAmountFromString(item.visited);

      clientsAmount += Number(item.clients);
      messagesAmount += Number(item.messages);
      visitedAmount += Number(visitedAmounts[0]);
      visitedAmountBrackets += Number(visitedAmounts[1]);
      transactionsAmount += Number(transactionsAmounts[0]);
      transactionsAmountBrackets += Number(transactionsAmounts[1]);
      retentionsAmount += Number(retentionsAmounts[0]);
      retentionsAmountBrackets += Number(retentionsAmounts[1]);
    });

    newItems.push({
      id: "total",
      affiliate: "Total",
      clients: clientsAmount,
      messages: messagesAmount,
      visited: `${visitedAmount} (${visitedAmountBrackets})`,
      transactions: `${transactionsAmount} (${transactionsAmountBrackets})`,
      retentions: `${retentionsAmount} (${retentionsAmountBrackets})`,
    });

    return newItems;
  }, [data]);

  const columns = useMemo(() => getColumnDefsStatisticsClients(), []);
  const filterFields = useMemo(() => getFilterClientsFields({
    channelsQuery: useGetChannelsQuery as OptionsQuery,
  }), []);

  const handleFilter = (values: StatisticClientsFilter) => {
    setFilter(values);
    return false;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <FilterForm
        className="mb-3"
        fields={filterFields}
        handleSubmit={handleFilter}
        initialValues={emptyStatisticsClientFilterValues}
      />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} fixed items={items} dataKey="statistics" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default StatisticsClients;
