import React, { useMemo, useState, useEffect, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import PageTitle from "../components/items/page-title";
import Table from "../components/tables/Table";
import { PaginationType } from "../components/tables/types";
import { OptionsQuery, PAGINATION } from "../types/global";
import Loading from "../components/Loading";
import { useCreateInvitationMutation, useLazyGetInvitationsQuery } from "../api/invitations";
import getColumnDefsInvitations from "../utils/invitationsColumn";
import { getCreateFields, getValidationSchema, initialValues } from "../utils/invitationsFields";
import { useGetUsersQuery } from "../api/users";
import { useGetChannelsQuery } from "../api/channels";
import { useAppSelector } from "../hooks/redux";
import { CreateInvitationType } from "../models/Invitation";
import Field from "../components/form/formik/Field";

const Invitations = () => {
  const [pagination, setPagination] = useState<PaginationType>(PAGINATION);
  const [showModal, setShowModal] = useState(false);
  const formikRef: React.Ref<FormikProps<CreateInvitationType>> | null = useRef(null);

  const { auth } = useAppSelector((state) => state.authSlice);
  const role = useMemo(() => auth.user?.role, [auth]);

  const [fetch, query, { lastArg }] = useLazyGetInvitationsQuery();
  const { data, isLoading } = query;

  const [createInvitation, { isLoading: createInvitationIsLoading }] =
    useCreateInvitationMutation();

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }

    fetch({
      pagination: { take: pagination.take, page: pagination.page },
    });
  }, [pagination]);

  const handleSubmit = (values: CreateInvitationType) => {
    createInvitation(values).then(() => setShowModal(false));
  };

  const columns = useMemo(() => getColumnDefsInvitations({ pagination }), [pagination]);

  const fields = useMemo(
    () =>
      getCreateFields({
        usersQuery: useGetUsersQuery as OptionsQuery,
        channelsQuery: useGetChannelsQuery as OptionsQuery,
      }),
    [],
  );

  const validationSchema = useMemo(() => getValidationSchema(role === "admin"), [role]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Invitations" />
      <Button className="mb-3" variant="primary" onClick={() => setShowModal(true)}>
        Create invitation
      </Button>
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Table
                setPagination={setPagination}
                className="min-w-screen-lg"
                columns={columns}
                containerClass="overflow-auto"
                fixed
                items={data?.list}
                pagination={pagination}
                dataKey="invitations"
              />
            </div>
          </div>
        </section>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>Create invitation</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik enableReinitialize validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
            <Form>
              <div className="row">
                {fields.map((field) => (
                  <Field key={field.name} {...field} size={12} />
                ))}
              </div>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => formikRef.current?.handleSubmit()}
            disabled={createInvitationIsLoading}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Invitations;
