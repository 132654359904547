import React from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import { FormControl } from "react-bootstrap";
import { ObjectWithKeys } from "../../../types/global";
import { IFieldDatepicker } from "../../../types/IField";
import { stringToDate, formatDateToState, formatDateAndTime } from "../../../utils/formats";

const DateField = <T extends ObjectWithKeys>({
  name,
  placeholder,
  label,
  showTime,
}: IFieldDatepicker<T>) => {
  const [, meta, helpers] = useField(name);
  const { value, touched, error } = meta;

  const selectedValue = stringToDate(value);

  const { setValue } = helpers;

  const handleChange = (date: Date) => {
    if (date) {
      setValue(!showTime ? formatDateToState(date) : formatDateAndTime(date));
    } else {
      setValue(null);
    }
  };

  return (
    <>
      {label && <label className="form-label-test">{label}</label>}
      <DatePicker
        className="form-control"
        onChange={handleChange}
        placeholderText={placeholder}
        isClearable
        selected={selectedValue}
        showTimeSelect={showTime}
        dateFormat="yyyy/MM/dd HH:mm:ss"
        timeFormat="HH:mm"
      />
      {touched && error ? (
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      ) : null}
    </>
  );
};

export default DateField;
