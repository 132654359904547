import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/items/page-title";
import Field from "../components/form/formik/Field";
import { getCreateFields, emptyInitialValues, validationSchema } from "../utils/walletsFields";
import { WalletCreate } from "../models/Wallet";
import { useCreateItemMutation } from "../api/wallets";
import { useGetCurrenciesQuery } from "../api/currencies";
import { OptionsQuery } from "../types/global";

const CreateWallet = () => {
  const navigate = useNavigate();
  const fields = useMemo(() => getCreateFields({
    currenciesQuery: useGetCurrenciesQuery as OptionsQuery,
  }), []);

  const [createWallet, { isLoading }] = useCreateItemMutation();

  const handleSubmit = async (values: WalletCreate) => {
    await createWallet(values);
    navigate("/wallets");
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/wallets">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating wallet" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={emptyInitialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                    disabled={isLoading}
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateWallet;
