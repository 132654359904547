import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Client, CloneClient } from "../models/Client";
import { TypesOfInput } from "../types/IField";
import { Button, Modal } from "react-bootstrap";
import {
  useCloneClientMutation,
  useGetClientsQuery,
  useUpdateTelegramIdMutation,
} from "../api/clients";
import Loading from "./Loading";
import Field from "./form/formik/Field";
import { OptionsQuery } from "../types/global";

interface Props {
  client: Client | null;
}

const ClientActions = ({ client }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [updateTelegramId, { isLoading: isLoadingUpdate }] = useUpdateTelegramIdMutation();
  const [cloneClient, { isLoading: isLoadingCloneClient }] = useCloneClientMutation();

  const handleChangeTelegramId = async (values: { telegramId: string }) => {
    if (!client) return;
    updateTelegramId({ ...values, id: client.id })
      .unwrap()
      .then(() => setShowModal(false));
  };

  const handleCloneClient = (values: CloneClient) => {
    if (!client) return;
    cloneClient(values)
      .unwrap()
      .then(() => setShowCloneModal(false));
  };

  if (!client) return <Loading />;

  return (
    <>
      <Button className="w-full" variant="primary" onClick={() => setShowModal(true)}>
        Change Telegram ID
      </Button>
      <Button className="w-full mt-3" variant="primary" onClick={() => setShowCloneModal(true)}>
        Clone client
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Telegram ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              telegramId: client.telegramId,
            }}
            validationSchema={Yup.object().shape({
              telegramId: Yup.string().required("Required"),
            })}
            onSubmit={handleChangeTelegramId}
          >
            <Form>
              <Field type={TypesOfInput.TEXT} name="telegramId" size={12} />
              <Button type="submit" disabled={isLoadingUpdate}>
                Save
              </Button>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={showCloneModal} onHide={() => setShowCloneModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Clone client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              clientId: client.id,
              clone: null,
            }}
            validationSchema={Yup.object().shape({
              clone: Yup.object().typeError("Required"),
            })}
            onSubmit={handleCloneClient}
          >
            <Form>
              <Field
                type={TypesOfInput.SELECT}
                name="clone"
                size={12}
                valueName="id"
                query={useGetClientsQuery as OptionsQuery}
                formatOptionLabel={(option) => (
                  <div>
                    {option.telegramId}
                    {option.username && <span> / {option.username}</span>}
                  </div>
                )}
              />
              <Button type="submit" disabled={isLoadingCloneClient}>
                Clone
              </Button>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientActions;
