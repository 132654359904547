import * as Yup from "yup";
import { ListItem } from "../types/global";

export type Auth = {
  email: string;
  password: string;
};

export type UserRole = "admin" | "manager" | "affiliate" | "payer";

export type IUser = ListItem<{
  name: string,
  email: string,
  role: UserRole,
}>

export type AuthResponse = {
  token: string;
  user: IUser;
};

export const emptyValues: Auth = {
  email: process.env.REACT_APP_USER || "",
  password: process.env.REACT_APP_PASSWORD || "",
};

export const validationSchema = Yup.object<Record<keyof Auth, Yup.AnySchema>>({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});

export const SUPER_ROLES: UserRole[] = ["admin", "manager", "payer"];
