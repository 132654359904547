import { createSlice } from "@reduxjs/toolkit";
import { getToken, removeCookies, setCookies } from "../../api/utils";
import { IUser } from "../../models/Auth";
import { getCurrentUser, login, logout } from "../thunks/auth";

export interface State {
  auth: {
    token: string | null;
    user: IUser | null;
  };
  isAuth: boolean;
}

const initialState: State = {
  auth: {
    token: null,
    user: null,
  },
  isAuth: !!getToken(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // login
    builder.addCase(login.fulfilled, (state, action) => {
      state.isAuth = true;
      state.auth = action.payload.model;
      setCookies("authorization", action.payload.model.token);
    });
    // logout
    builder.addCase(logout.fulfilled, (state) => {
      state.auth = { ...initialState.auth };
      state.isAuth = false;
      removeCookies("authorization");
    });

    // // getCurrentUser
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.auth.user = action.payload.model;
       // state.auth.user = {
      //   ...action.payload.model,
      //   role: 'manager',
      // }
    });
  },
});

export default authSlice.reducer;
